export default {
    storeResume(context, file, options = []) {
        const url = 'v1/me/resumes';
        const data = new FormData();
        data.append('file', file);
        if (options.length) {
            Object.entries(options).forEach((o) => {
                data.append(o[0], o[1]);
            });
        }
        return this.$axios.$post(url, data);
    },

    destroyResume(context, id) {
        const url = `v1/me/resumes/${id}`;
        return this.$axios.$delete(url);
    },

    updateResume(context, { id, data }) {
        const url = `v1/me/resumes/${id}`;
        return this.$axios.$put(url, data);
    },

    makeDefaultResumeFresh() {
        const url = 'v1/me/resume/make-default-fresh';
        return this.$axios.$put(url);
    },

    getDefaultResume() {
        const url = 'v1/me/resumes?type=default';
        return this.$axios.$get(url);
    },

    getResume() {
        const url = 'v1/me/resumes';
        return this.$axios.$get(url);
    },

    downloadResume(context, id) {
        const url = `v1/me/download/resume/${id}`;
        return this.$axios.$get(url, {
            responseType: 'blob',
            timeout: 30000,
        });
    },

    downloadResumeByURL(context, id) {
        return this.$axios.$get(`v1/me/download/resume-by-url/${id}`);
    },

    storeTopResume(context, data) {
        const url = 'v1/me/partners/top-resume';
        return this.$axios.$post(url, data);
    },
};
