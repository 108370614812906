import Vue from 'vue';

export default () => {
    if (process.browser) {
        Vue.directive('scroll', {
            bind: (el, binding) => {
                el.scrollEvent = evt => binding.value(evt, el);
                window.addEventListener('scroll', el.scrollEvent);
            },
            unbind: (el) => {
                window.removeEventListener('scroll', el.scrollEvent);
            },
        });

        Vue.directive('resize', {
            bind: (el, binding) => {
                el.resizeEvent = evt => binding.value(evt, el);
                window.addEventListener('resize', el.resizeEvent);
            },
            unbind: (el) => {
                window.removeEventListener('resize', el.resizeEvent);
            },
        });

        Vue.directive('click-outside', {
            bind: (el, binding, vnode) => {
                el.clickOutsideEvent = (event) => {
                    // here I check that click was outside the el and his childrens
                    if (!(el === event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener('click', el.clickOutsideEvent);
            },
            unbind: (el) => {
                document.body.removeEventListener('click', el.clickOutsideEvent);
            },
        });
    }
};
